<template>
    <b-container class="pt-5">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8">
                <h3>Reset Password</h3>

                <b-card class="mb-3">
                    <b-form @submit.prevent="resetPassword">
                    <b-form-group
                        id="emailGroup"
                        label="Email address"
                        label-for="emailInput"
                    >
                        <b-form-input
                        id="emailInput"
                        type="email"
                        autocorrect="off"
                        autocapitalize="none"
                        v-model.trim="form.email"
                        required
                        placeholder="email@domain"
                        ></b-form-input>
                    </b-form-group>

                    <b-button
                        size="lg"
                        block
                        class="mb-3"
                        type="submit"
                        variant="primary"
                        :disabled="false"
                    >
                        Reset
                        <b-spinner small v-if="false" />
                    </b-button>
                    </b-form>
                </b-card>

                <h4 class="text-center my-3">you will be sent email to reset password</h4>

                <h2 class="text-center my-3">OR</h2>

                <b-button
                    size="lg"
                    block
                    class="mb-3"
                    @click="resetToken"
                    variant="primary"
                    :disabled="false"
                >
                    Enter Reset Token
                    <b-spinner small v-if="false" />
                </b-button>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    name: "reset",
    data () {
        return {
            form: {
                email: ""
            }
        };
    },
    methods:  {
        async resetPassword () {
            try {
                let response = await this.$http.put(
                this.$store.state.apiURL + "/account/reset-password",
                this.form
                );
                
                if (response.status == 200) {
                    console.log ("reset password succeeded");
                }
            } catch (error) {
                this.handleError(error);

                console.log ("error with reset password");
            }

            this.$router.push({ path: "/login" });
        },
        resetToken () {
            console.log ("reset token");

            this.$router.push({path: "/reset-token"})
        }
    }
}
</script>

<style lang="scss">
@import "../../scss/theme.scss";

h4 {
  color: #003e69;
}
</style>
